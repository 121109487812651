import {KeyCodes} from "./constants";

export class MdlSelect {
    private $globalContainer: JQuery;
    private $input: JQuery;
    private $hiddenInput: JQuery;
    private $menu: JQuery;
    private $mdlMenuContainer: JQuery;
    private $list: JQuery;
    private text: string = '';
    private isOpened = false;
    private static currentSelect: MdlSelect = null;

    public static globalOptions: MdlSelectOptions = {
        globalContainerSelect : ".content .scrollbar-container .main-content",
        borderWidth: 6,
        maxDropdownHeight: 300,
        setSelectedValueOnInit: true,
    };
    
    constructor(private $dropdown: JQuery, private options?: MdlSelectOptions) {
        if($dropdown.is(".mdl-select-initialized")){
            throw "MdlSelect is already initialized.";
        }
        $dropdown.addClass("mdl-select-initialized");
        
        this.options = $.extend(MdlSelect.globalOptions, options);
        this._initElements();
        this._initInputEvents();
        this._initListElementsEvents();
        this._exposePublicApi();
    }

    static openMenu(value: MdlSelect)
    {
        if (this.currentSelect != value){
            if (this.currentSelect != null)
                this.currentSelect.close();
            this.currentSelect = value;
            if (this.currentSelect != null)
                this.currentSelect.open();
        }
    }

    static closeMenu()
    {
        if (this.currentSelect != null)
            this.currentSelect.close();
        this.currentSelect = null;
    }

    /** 
     * Opens the dropdown above or below the input, depending on distance to bottom of the page
     * Adds "show" class to the dropdown element
     * Adds "top-menu" when dropdown is above the input
     * */
    public open(): void {
        const width = this.$dropdown.width();
        let top = this.$input.offset().top - this.$globalContainer.offset().top + this.$input.outerHeight();
        const left = this.$dropdown.offset().left - this.$globalContainer.offset().left;
        
        let distanceToPageBottom = this.$globalContainer.outerHeight() + this.$globalContainer.offset().top
            - this.$dropdown.offset().top - this.$dropdown.height();
        const isTopMenu = distanceToPageBottom < this.options.maxDropdownHeight;
        if(isTopMenu) {
            const offset = Math.min(this.options.maxDropdownHeight, this.$menu.height());
            top -= offset + this.$input.outerHeight();
        }
        
        this.$menu.toggleClass("top-menu", isTopMenu);
        this.$menu.addClass("show");
        this.$menu.css({
            top: top,
            left: left,
            width: width
        });
        this.isOpened = true;

        this._scrollToSelected();
    }

    /**
     * Closes the dropdown
     */
    public close(): void {
        this.$menu.removeClass("show");
        this._blur();
        this.isOpened = false;
    }

    /**
     * Select option by it's text
     */
    public setText(text: string): void {
        this.$list.each((index, li) => {
            if($(li).text() === text) {
                this._selectItem($(li));
                this._validate();
            }
        });
    }

    /**
     * Select option by it's value
     */
    public setValue(value: string): void {
        this.$list.each((index, li) => {
            if($(li).data("val").toString() === value) {
                this._selectItem($(li));
                this._validate();
            }
        });
    }

    /**
     * Select option by it's index
     */
    public setByIndex(index: number): void {
        this._selectItem($(this.$list[index]));
    }
    
    /**
     * Returns selected item text
     * @returns {*|string|*}
     */
    public getText(): string {
        return this.$input.val() as any;
    }

    /**
     * Returns selected item value
     * @returns {*|string|number|*}
     */
    getValue(): string | number {
        return this.$hiddenInput.val()  as any;
    }

    /**
     * Update dropdown item list
     * @private
     */
    private setSource(selectItemsList: { Text: string, Value: string | number }[], selected?: string, defaultLabel?: string): void {
        this.$menu.empty();

        if (defaultLabel) {
            selectItemsList = [...[{ Text: defaultLabel, Value: 0 }], ...selectItemsList];
        }

        let list: JQuery[] = selectItemsList.map((el: { Text: string, Value: string }) => {
            return $(`<li class="dropdown-item" data-val="${el.Value}">${el.Text}</li>`);
        });
        this.$menu.append(list);
        this.$list = $("li", this.$menu);
        this._initListElementsEvents();
        this.setValue(selected);
    }

    /**
     * Initialize the Jquery elements and moves the dropdown to the specific section 
     * @private
     */
    private _initElements(): void {
        this.$globalContainer = $(this.options.globalContainerSelect);
        this.$input = $('input.mdl-textfield__input', this.$dropdown);
        this.$hiddenInput = $('input.invisible-for-autocomplete', this.$dropdown);
        this.$menu = $('ul', this.$dropdown);
        this.$mdlMenuContainer = this._getMdlMenuContainer();
        this.$menu.appendTo(this.$mdlMenuContainer);
        this.$list = $('li', this.$menu);
    }

    private _exposePublicApi() {
        this.$dropdown.data("mdl-select", {
            setText: (text: string) => this.setText(text),
            setValue: (value: string) => this.setValue(value),
            setByIndex: (index: number) => this.setByIndex(index),
            getText: () => this.getText(),
            getValue: () => this.getValue(),
            open: () => MdlSelect.openMenu(this),
            close: () => MdlSelect.closeMenu(),
            setSource: (selectItemsList: { Text: string, Value: string }[], selected?: string, defaultLabel?: string) => this.setSource(selectItemsList, selected, defaultLabel),
        }); 
    }

    private _initInputEvents() {
        this.$input
            .on("focus", () => {
                MdlSelect.openMenu(this);
            })
            .on("blur", (e) => {
                e.preventDefault();
                e.stopPropagation();
                MdlSelect.closeMenu();
            })
            .on("click", (e) => {
                if(!this.isOpened) {
                    this.$input.focus();
                    MdlSelect.openMenu(this);
                }
            })
            .on("keydown", (event) => {
                if (event.keyCode === KeyCodes.Esc) {
                    MdlSelect.closeMenu();
                } else {
                    this._handleTextInput(event);
                }
            });
    }

    private _initListElementsEvents() {
        this.$list.toArray().map((li) => {
            const $li = $(li);

            $li.on("mousedown", (e) => {
                e.preventDefault();
                this._selectItem($li);
                this._validate();
                MdlSelect.closeMenu();
            });

            if (this.options.setSelectedValueOnInit && $li.data("selected")) {
                this._selectItem($li);
            }
        });
    }

    /**
     * Returns special container for dropdowns. This container is a solution for z-index issue
     * @returns {*|jQuery|HTMLElement}
     * @private
     */
    private _getMdlMenuContainer() {
        let $container = $(".mdl-menu-container");
        if($container.length === 0){
            $container = $("<div class='mdl-menu-container'>");
            this.$globalContainer.append($container);
        }
        return $container;
    }

    /**
     * Sets $li as selected item
     * @param $li
     * @private
     */
    private _selectItem($li: JQuery) {
        const value = $li.text().trim();
        this.$input.val(value);
        this.$hiddenInput.val($li.data("val"));

        this.$list.removeClass("selected");
        $li.addClass('selected');

        this.$input.trigger("change");
        this.$hiddenInput.trigger("change");
        MaterialUtils.updateTextFields(this.$dropdown.parent());
    }

    private _selectItemByIndex(index: number) {
        this._selectItem($(this.$list[index]));
    }

    private _getSelectedItemIndex() {
        let index = -1;
        this.$list.toArray().some((el, i) => {
            if ($(el).hasClass("selected")) {
                index = i;
                return true;
            }
        });
        return index;
    }

    private _blur() {
        this.$dropdown.removeClass("is-focused");
        this.$hiddenInput.blur();
    }

    private _scrollToSelected() {
        let index = this._getSelectedItemIndex();
        if (index !== -1 && this.$menu) {
            scrollParentToChild({
                parent: this.$menu[0],
                child: this.$list[index]
            });
        }
    }

    /**
     * Handler for value autocomplete when type it
     * @param event
     * @private
     */
    private _handleTextInput(event: any) {
        switch (event.keyCode) {
            case KeyCodes.Tab:
            case KeyCodes.ArrowLeft:
            case KeyCodes.ArrowRight:
                return;
            case KeyCodes.Enter:
                this.text = "";
                MdlSelect.closeMenu();
                return;
            case KeyCodes.ArrowUp:
                this.text = "";
                event.preventDefault();
                this._selectPrevItem();
                return;
            case KeyCodes.ArrowDown:
                this.text = "";
                event.preventDefault();
                this._selectNextItem();
                return;
        }
        
        const options = this.$list.toArray().map((li) => $(li).text().trim().toLowerCase());

        this.text += event.char || event.key;

        let index = this._findIndex(options, this.text);
        if(index === -1) {
            this.text = event.key;
            index = this._findIndex(options, this.text);
        }
        if(index !== -1) {
            this._selectItemByIndex(index);
            this._scrollToSelected();
            this._validate();
        }
    }

    private _selectNextItem(){
        const index = this._getSelectedItemIndex();
        if(index < this.$list.toArray().length - 1){
            this._selectItemByIndex(index + 1);
            this._scrollToSelected();
            this._validate();
        }
    }

    private _selectPrevItem(){
        const index = this._getSelectedItemIndex();
        if(index > 0) {
            this._selectItemByIndex(index - 1);
            this._scrollToSelected();
            this._validate();
        }
    }

    private _findIndex(options: string[], str: string) {
        if(str) {
            const availableOptions = options.filter((e) => e.startsWith(str.toLowerCase()));
            if(availableOptions.length > 0){
                return options.indexOf(availableOptions[0]);
            }
        }
        return -1;
    }

    private _validate() {
        try {
            (this.$hiddenInput as any).valid();
        } catch (e) {
            console.warn("There is no validation for the input");
        }
    }

    public static initAll($container: JQuery, options?: MdlSelectOptions) {
        $container = $container || $("body");
        $(".mdl-select:not(.mdl-select-initialized)", $container).each((index, element) => {
            new MdlSelect($(element), options);
        });
    }
}

MdlSelect.initAll($(".mdl-select-js-init"));